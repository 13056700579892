<template>
  <div class="app">
    <CRow>
      <CCol sm="4">
        <h4 class="text-uppercase">Payments</h4>
      </CCol>

      <CCol sm="8"> </CCol>
    </CRow>

    <CRow>
      <CCol sm="12">
        <CCard>
          <CRow>
            <CCol sm="12">
              <form v-on:submit.prevent="filterPayment(filter)">
                <CRow>
                    <CCol sm="6" xl="6"></CCol>
                    <CCol sm="6" xl="6">
                        <CRow>
                            <CCol md="4" class="pr-0">
                                <div class="form-group">
                                <select
                                    name="status"
                                    v-model="filter.status"
                                    class="form-control"
                                    @change="filterPayment(filter)"
                                >
                                    <option value>Select Status</option>
                                    <option value="Successful">Successful</option>
                                    <option value="Failed">Failed</option>
                                    <option value="Initiate">Initiate</option>
                                </select>
                                </div>
                            </CCol>

                            <CCol md="4">
                                <button class="btn btn-success w-100">Search</button>
                            </CCol>
                        </CRow>
                    </CCol>

                </CRow>
              </form>
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="12">
              <div class="table-responsive">
                <table class="table table-borderless">
                  <thead>
                    <tr class="text-center">
                      <th scope="col">Payment For</th>
                      <th scope="col">Payment Method</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Transaction ID</th>
                      <th scope="col">Status</th>
                      <th scope="col">Paid At</th>
                    </tr>
                  </thead>
                  <tbody v-if="payments.length">
                    <tr
                      class="text-center"
                      v-for="payment in payments"
                      :key="payment.id"
                    >
                      <td scope="row">{{ payment.payment_type }}</td>
                      <td scope="row">{{ payment.payment_method }}</td>
                      <td scope="row">{{ payment.amount }}</td>
                      <td scope="row">{{ payment.transaction_id }}</td>
                      <td scope="row">
                          <span class="badge payment-status" :class="payment.status == 'Successful' ? ' badge-success' : 'badge-danger'">{{ payment.status }}</span>
                      </td>

                      <td scope="row">{{ payment.created_at | moment }}</td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="6">
                        <h5 class="text-center">Data Not Available.</h5>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </CCol>
          </CRow>
          <CPagination
              v-if="payments.length"
              align="center"
              :pages="rows"
              :active-page.sync="filter.currentPage"
              @update:activePage="handlePageChange"
          />
        </CCard>
      </CCol>
    </CRow>

  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Payments",
  data: () => {
    return {
      isLoading: false,
      filter: {
        status: "",
        currentPage: 1,
      },
      deletingState: false,
      loadingState: false,
      role: {},
      serverErrors: [],
      showDismissibleAlert: false,
      currentPage: 1,
    };
  },
  components: {

  },
  methods: {
    filterPayment(search) {
      if (search) {
        search.currentPage = 1;
        this.$router.replace({name: "Payments", query: this.filter});
        this.$store.dispatch("Payment/getPayments", search);
      }
    },
    handlePageChange(value) {
        this.filter.currentPage = value;
        this.$router.replace({name: "Payments", query: this.filter});
        this.$store.dispatch("Payment/getPayments", this.filter);
    },
    setSearchParams() {
      this.filter.status = this.$route.query.status?this.$route.query.status:'';
      this.filter.currentPage = this.$route.query.currentPage?parseInt(this.$route.query.currentPage):1;
    }
  },
  computed: {
    ...mapGetters("Payment", ["payments", "rows", "perPage"]),
  },
  filters: {
    moment: function (date) {
      return moment(date).format("DD MMM, YYYY");
    },
  },
  mounted() {
    this.setSearchParams();
    this.$store.dispatch("Payment/getPayments", this.filter);
  },
};
</script>

<style scoped>
.custom-button {
  color: #000;
  padding: 10px 8px 10px 15px;
  font-size: 15px;
  border-bottom: 1px solid rgba(204, 204, 204, 0.4);
  line-height: 1;
}

.card {
    border: none;
    padding: 15px 15px 0;
}
tbody:before {
  content: "@";
  display: block;
  line-height: 30px;
  text-indent: -99999px;
}
.payment-status{
    font-size: 12px;
    font-weight: 100;
}
</style>

<style lang="scss" scoped>
div {
  h4 {
    font-size: 15px;
    font-weight: bold !important;
    color: #000;
  }
  .table {
    font-size: 14px;

    thead {
      tr {
        th {
          background: #f8f9fa;
          color: #000;
        }
      }
    }
    tbody {
      font-size: 13px;
      tr {
        color: #000;
        td {
          padding: 0.75rem 0 0.75rem 0.75rem;
          button {
            font-size: 13px;
          }
        }
        td:nth-child(2) {
          color: #5e76e7;
        }
        td:nth-child(2) {
          font-weight: 500 !important;
        }
      }
    }
  }
}
</style>
